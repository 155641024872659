:root {
    --main-color-gradient: linear-gradient(90deg, #1e3a8e 0%, #667fd1 100%);
    --main-color-opacity: #1e3a8e46;

    --main-color-1: #1e3a8e;
    --main-color-2: #607ed6;
    --main-color-3: #a8c2ff;
    --main-color-4: #C7D5FC;
    --main-color-5: #ECEFFF;

    --secondary-color-1: #002053;
    --secondary-color-2: #f1f5ff;
}
